import { apiHomeSlice } from "@/redux/api/apiSlice";
import { Blog } from "@/redux/types";

export const homeApiSlice = apiHomeSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBlogs: builder.query<Blog[], void>({
      query: () => ({
        url: `/blogs/`,
      }),
      providesTags: ["Home"],
    }),
    getBlogById: builder.query<Blog, string>({
      query: (id) => ({
        url: `/blogs/${id}/`,
      }),
      providesTags: ["Home"],
    }),
    sendContactUsMessage: builder.mutation<
      void,
      {
        full_name: string;
        email: string;
        message: string;
        phone: string;
        company_name: string;
        date: Date;
        is_request_demo?: boolean;
      }
    >({
      query: (contactMessage) => ({
        url: `/contacts/`,
        method: "POST",
        body: contactMessage,
      }),
      invalidatesTags: ["Home"],
    }),
  }),
});

export const {
  useGetAllBlogsQuery,
  useGetBlogByIdQuery,
  useSendContactUsMessageMutation,
} = homeApiSlice;
