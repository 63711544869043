import { Dialog, DialogContent } from "@/components/ui/dialog";
import Image from "next/image";
import React from "react";
import successImage from "@/public/images/assets/success.svg";
import failImage from "@/public/images/assets/fail.svg";
import closeIcon from "@/public/images/icons/close.svg";

interface CustomToasterProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  subTitle: string;
  type: "success" | "error";
}

const CustomToaster: React.FC<CustomToasterProps> = ({
  open,
  onOpenChange,
  title,
  subTitle,
  type,
}) => {
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
    >
      <DialogContent className="bg-[#07031A] border border-[#1B1239] rounded-2xl w-full max-w-md p-6">
        {/* Close Button */}
        <div className="relative pt-9">
          <button
            className="absolute top-0 left-0 bg-[#110D24] p-4 rounded-lg"
            onClick={() => onOpenChange(false)}
            aria-label="Close"
          >
            <Image
              src={closeIcon}
              alt="close"
            />
          </button>

          <div className="flex flex-col justify-start items-center text-center gap-6">
            <Image
              src={type === "success" ? successImage : failImage}
              alt={type === "success" ? "Success" : "Error"}
              className={"max-w-[50%] w-full"}
            />
            <div className="text-white text-[25px] font-semibold">{title}</div>
            <div className="text-[#928cb4] capitalize leading-snug">
              {subTitle}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CustomToaster;
